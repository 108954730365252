import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  buildHttpParams,
  OneAppDtoRequest,
} from '@shared-services/apps-dto-request/apps-dto-request.class';
import { DealersDealerOfficeDealersGetEndpoint } from '@tes/apps-dto';
import { map, Observable } from 'rxjs';
import { DealerOfficeFindRequestDto } from '@shared-services/api/dealer-office/dtos/dealer-office-find.request.dto';
import { DealerOfficeFindResponseDto } from '@shared-services/api/dealer-office/dtos/dealer-office-find.response.dto';
import { DealerOfficesCityWithDealersResponseDto } from './dtos/dealer-offices-city-with-dealers-response.dto';
import { DealerOfficePutRequest } from './dtos/dealer-office-put.request';

@Injectable({ providedIn: 'root' })
export class DealerOfficeApiService {
  constructor(private readonly http: HttpClient) {}

  public getOfficeList(): Observable<DealersDealerOfficeDealersGetEndpoint.Response> {
    const request = new OneAppDtoRequest<
      DealersDealerOfficeDealersGetEndpoint.RequestBody,
      DealersDealerOfficeDealersGetEndpoint.RequestPathParams,
      DealersDealerOfficeDealersGetEndpoint.RequestQueryParams,
      DealersDealerOfficeDealersGetEndpoint.RequestHeaders,
      DealersDealerOfficeDealersGetEndpoint.Response
    >(this.http, {
      baseUrl: '',
      type: DealersDealerOfficeDealersGetEndpoint.type,
      url: DealersDealerOfficeDealersGetEndpoint.endPointPath,
      getUrl: DealersDealerOfficeDealersGetEndpoint.getUrl,
    });

    return request.execute({
      query: {},
      path: {},
      headers: {},
      body: undefined,
    });
  }

  find(
    params: DealerOfficeFindRequestDto
  ): Observable<DealerOfficeFindResponseDto> {
    return this.http
      .get<{ data: DealerOfficeFindResponseDto }>(
        '/tes-dealers/dealer-office',
        {
          params: buildHttpParams(params),
        }
      )
      .pipe(map((response) => response.data));
  }

  findAdmin(params: DealerOfficeFindRequestDto) {
    return this.http
      .get<{ data: DealerOfficeFindResponseDto }>(
        '/tes-dealers/admin/dealer-offices',
        {
          params: buildHttpParams(params),
        }
      )
      .pipe(map((response) => response.data));
  }

  getCityDealersList() {
    return this.http
      .get<{
        data: DealerOfficesCityWithDealersResponseDto;
      }>('/tes-dealers/dealer-office/city-dealers-list')
      .pipe(map((response) => response.data));
  }

  putDealerOffice(sapCode: string, data: DealerOfficePutRequest) {
    return this.http.put<void>(`/tes-dealers/dealer-office/${sapCode}`, data);
  }
}
