import {
  Dealer,
  PROFILE_VERSION,
} from '@global-services/profile-service/shared/profile.interface';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { map, Observable, switchMap, tap, throwError } from 'rxjs';
import {
  ImpersonateProfileAction,
  ResetImpersonationProfileAction,
  SetProfileAction,
} from '@global-services/profile-service/profile.actions';
import { TokenControlService } from '@global-services/token-service/token-control.service';
import { CleanerControlService } from '@global-services/cleaner-service/cleaner-control.service';
import { ComponentStore } from '@ngrx/component-store';
import { LoaderService } from '@pages-core/main-container-page/loader.service';
import { finalize } from 'rxjs/operators';
import { SidenavMenu } from '@components/sidenav/shared/login-params.interface';

interface LoginParams {
  username: string;
  password: string;
}

interface ImpersonateParams {
  login: string;
}

export interface LoginProfileResponse {
  logoUrl: string | null;
  name: string;
  email: string;
  permissions: string[];
  dealers: Dealer[];
}

interface LoginResponse {
  data: {
    accessToken: string;
    profile: LoginProfileResponse;
  };
}

@Injectable()
export class AuthService extends ComponentStore<never> {
  constructor(
    private httpClient: HttpClient,
    private tokenControlService: TokenControlService,
    private route: Router,
    private ngZone: NgZone,
    private store: Store,
    private cleanerControlService: CleanerControlService,
    private loaderService: LoaderService
  ) {
    super();
  }

  login(params: LoginParams) {
    return this.httpClient
      .post<LoginResponse>('/tes-dealers/auth/login', params)
      .pipe(
        map((response) => response.data),
        tap(({ accessToken }) =>
          this.tokenControlService.setToken({
            accessToken: accessToken,
          })
        ),
        map((response) => response.profile),
        tap(({ name, email, dealers, logoUrl, permissions }) => {
          this.store.dispatch(
            new SetProfileAction({
              name,
              email,
              dealers,
              logoUrl,
              roles: permissions,
              version: PROFILE_VERSION,
            })
          );
        }),
        tap(() => this.ngZone.run(() => this.route.navigate([''])))
      );
  }

  impersonate(request: ImpersonateParams): Observable<unknown> {
    this.loaderService.setLoading('IMPERSONATION', true);

    return this.httpClient
      .post<LoginResponse>(`/tes-dealers/auth/impersonate`, request)
      .pipe(
        map((response) => response.data),
        switchMap((response) =>
          this.cleanerControlService.cleanDb().pipe(map(() => response))
        ),
        tap(({ accessToken }) =>
          this.tokenControlService.setToken({
            accessToken,
            hostAccessToken:
              this.tokenControlService.currentAccessToken?.accessToken,
          })
        ),
        map((response) => response.profile),
        tap(({ name, email, dealers, logoUrl, permissions }) =>
          this.store.dispatch(
            new ImpersonateProfileAction({
              name,
              email,
              dealers,
              logoUrl,
              roles: permissions,
              version: PROFILE_VERSION,
            })
          )
        ),
        tap(() => this.ngZone.run(() => this.route.navigate(['']))),
        tap(() => window.location.reload()),
        finalize(() => this.loaderService.setLoading('IMPERSONATION', false))
      );
  }

  clearImpersonation(): Observable<unknown> {
    const tokens = this.tokenControlService.currentAccessToken;

    if (!tokens?.hostAccessToken) {
      return throwError(() => new Error());
    }

    this.tokenControlService.setToken({
      accessToken: tokens.hostAccessToken,
    });

    this.store.dispatch(new ResetImpersonationProfileAction());

    return this.cleanerControlService.cleanDb().pipe(
      tap(() => this.ngZone.run(() => this.route.navigate(['']))),
      tap(() => window.location.reload()),
      finalize(() => this.loaderService.setLoading('IMPERSONATION', false))
    );
  }

  logout(): Observable<unknown> {
    return this.httpClient.post('/tes-dealers/auth/logout', {}).pipe(
      tap(() => this.tokenControlService.removeToken()),
      tap(() => this.cleanerControlService.clean()),
      tap(() => this.ngZone.run(() => this.route.navigate(['login'])))
    );
  }

  getMenu(): Observable<SidenavMenu[]> {
    return this.httpClient
      .get<{ data: SidenavMenu[] }>('/tes-dealers/auth/menu')
      .pipe(map((i) => i.data));
  }

  getHomeRedirect(): Observable<{ url: string | null }> {
    return this.httpClient
      .get<{ data: { url: string | null } }>('/tes-dealers/auth/home')
      .pipe(map((response) => response.data));
  }

  resetPassword(login: string) {
    return this.httpClient.post<void>(
      '/tes-dealers/auth/request-password-reset',
      { login }
    );
  }

  resetPasswordByToken(token: string) {
    return this.httpClient.post<void>(
      '/tes-dealers/auth//reset-password-by-token',
      { token }
    );
  }
}
